import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { PoschodochBanner } from "../components/poschodoch/PoschodochBanner/PoschodochBanner";
import { PoschodochBreadcrumb } from "../components/poschodoch/PoschodochBreadcrumb/PoschodochBreadcrumb";
import { PoschodochContent } from "../components/poschodoch/PoschodochContent/PoschodochContent";

const PoschodochPage = () => (
  <Layout>
    <PoschodochBreadcrumb />
    <PoschodochBanner />
    <PoschodochContent />
  </Layout>
);

export default PoschodochPage;
