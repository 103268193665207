import React from "react";
import styled from "styled-components";
import { PageBreadcrumb } from "../../Shared/PageBreadcrumb/PageBreadcrumb";
import { useTranslation } from "react-i18next";

export interface PoschodochBreadcrumbProps {
  className?: string;
}

const PoschodochBreadcrumbInner = (props: PoschodochBreadcrumbProps) => {
  const { t } = useTranslation("common");
  return <PageBreadcrumb breadCrumbText={t("routes.poschodoch")} />;
};

export const PoschodochBreadcrumb = PoschodochBreadcrumbInner;
