import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { HeaderUnderline } from "../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import {
  ServiceListItem,
  ServiceListItemProps
} from "../../sluzby/components/ServiceListItem/ServiceListItem";

export interface PoschodochContentProps {
  className?: string;
}

const PoschodochContentInner = (props: PoschodochContentProps) => {
  const { t } = useTranslation("poschodoch");
  const content: ServiceListItemProps = t("content.paragraph", {
    returnObjects: true
  });

  return (
    <Section className={props.className}>
      <Container>
        <HeaderUnderline>{t("content.header.title")}</HeaderUnderline>
        <ServiceListItem {...content} />
        <p>
          Portál je prevádzkovaný spoločnosťou ANASOFT APR, spol. s
          r.o.(Prevádzkovateľ), Mlynská dolina 41, 811 02 Bratislava, Tel. +421
          2 32234484, IČO: 31361552, DIČ: 2020345778, právnická osoba je
          zapísaná v obchodnom registri Okresného súdu Bratislava I v oddiele
          Sro, v vložke č. 6042/B.
        </p>
      </Container>
    </Section>
  );
};

export const PoschodochContent = styled(PoschodochContentInner)``;
