import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { usePoschodochBannerImage } from "./hooks/usePoschodochBannerImage";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { HeroText } from "../../Shared/HeroText/HeroText";
import { CtaButton } from "../../home/HeroSection/HeroSection";
import { routes } from "../../../lib/routes";
import { muiTheme } from "../../../lib/Theme";

export interface PoschodochBannerProps {
  className?: string;
}

const PoschodochBannerImage = styled(BackgroundImage)`
  &::before,
  &::after {
    filter: brightness(0.8);
  }
`;

const PoschodochBannerInner = (props: PoschodochBannerProps) => {
  const image = usePoschodochBannerImage();
  const { t } = useTranslation("poschodoch");
  return (
    <PoschodochBannerImage fluid={image}>
      <Container>
        <section className={props.className}>
          <HeroText>{t("hero.text")}</HeroText>
          <CtaButton target="_blank" to={"https://www.poschodoch.sk/"}>
            {t("hero.cta")}
          </CtaButton>
        </section>
      </Container>
    </PoschodochBannerImage>
  );
};

export const PoschodochBanner = styled(PoschodochBannerInner)`
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  overflow-x: hidden;
  ${HeroText} {
    margin-bottom: 30px;
    width: 60%;
    ${muiTheme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
`;
